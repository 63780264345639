import { findall } from './_util.js'

var _renderer = 'g1.template.render'
var _compiled = 'g1.template.compiled'
var _prev_created = 'g1.template.prev_created'


function subtemplates($main) {
  // Takes a main template as a jQuery node with data-template-<name>="selector" attributes.
  // Return an object of {name: selector}
  return _.chain($main.data())
    .pickBy(function (val, key) { return key.match(/^template/) })
    .mapKeys(function (val, key) { return key.replace(/^template/, '').toLowerCase() })
    .value()
}

export function template(data, options) {
  options = options || {}
  var self = this
  var selector = options.selector || self.data('selector') || 'script[type="text/html"],template'

  // Pre-create the template rendering function
  // Store this in .data('template.function')
  findall(self, selector).each(function () {
    var $this = $(this)
    // If we want to dispose the last target, just dispose it.
    if (data === 'dispose') {
      var $oldtarget = $this.data(_prev_created)
      if ($oldtarget)
        $oldtarget.remove()
      return $this.trigger({
        type: 'template',
        templatedata: data,
        target: $oldtarget
      })
    }
    var renderer = $this.data(_renderer)
    // If the renderer is already present, just use it. Else compile it
    if (renderer)
      renderer(data, options)
    // If there are subtemplate dependencies, compile them
    else if (_.size(subtemplates($this)))
      dependent_templates($this, self, data, options)
    // If there aren't, then compile immediately.
    else
      make_template($this, data, options)
  })
  return this
}

function dependent_templates(selector, self, data, options) {
  var uncompiled_selectors = _.pickBy(subtemplates(selector), function (sel) {
    return !$(sel).data(_compiled)
  })
  return Promise.all(
    _.map(uncompiled_selectors, function (sel) { return dependent_templates($(sel), self, data, options) })
  ).then(function () {
    return Promise.all(
      _.union(
        _.map(uncompiled_selectors, function (sel) { return make_template($(sel), data, options) }),
        make_template(selector, data, options)
      )
    )
  })
    .catch(function (error) { console.error(error) }) // eslint-disable-line no-console
}

function make_template($this, data, options) {
  // Compile templates. If the template has src="", load it and then compile it.
  // The result is in $this.data(_compiled) (via make_template_sync).
  var html = $this.html()
  // Contents of script are regular strings. Contents of template are escaped
  if (!$this.is('script'))
    html = _.unescape(html)

  var src = $this.attr('src')
  if (src) {
    // If the AJAX load succeeds, render the loaded template
    // Else render the contents, with an additional xhr variable
    return $.get(src).done(function (html) {
      make_template_sync($this, html, data, options)
    }).fail(function (xhr) {
      data.xhr = xhr
      make_template_sync($this, html, data, options)
    })
  }
  // If no src= is specified, just render the contents
  else make_template_sync($this, html, data, options)
}


// Bind a template renderer to the node $this.data('template.render')
// This renderer function accepts (data, options) and creates
//    - runs the html template, parses the result, and create a target node
//    - appends the target node after $this (clearing any previous target nodes)
//    - stores the target node in $this.data('template.target')
//    - triggers a template event (with .templatedata, .target)
//    - returns the target node
function make_template_sync($this, html, data, default_options) {
  var compiled_template = _.template(html)
  var $created

  // $this.data(_compiled) has the compiled template. This adds sub-template
  // variables from data-template-* attributes.
  $this.data(_compiled, function (subtemplate_data) {
    subtemplate_data = _.extend(
      {$node: $this, $data: $this.data()},
      _.mapValues(subtemplates($this), function (selector) { return $(selector).data(_compiled) }),
      subtemplate_data || {}
    )
    return compiled_template(subtemplate_data)
  })

  function renderer(data, options) {
    html = $this.data(_compiled)(data)
    // Get options. DOM data-* over-rides JS options
    var append = $this.data('append') || (options ? options.append : default_options.append)
    var target = $this.data('target') || (options ? options.target : default_options.target)
    var engine = $this.data('engine') || (options ? options.engine : default_options.engine)
    if (!engine || typeof engine == 'string')
      engine = template.engines[engine] || template.engines['default']
    // If we're appending the contents, just add the text
    if (append) {
      $created = $($.parseHTML(html.trim()))
      // If we're appending to a target node, just append to it.
      if (target)
        $(target).append($created)
      // If no target node, add BEFORE template. Future appends will be in sequence
      else
        $this.before($created)
    }
    // If we're not appending, replace the contents using the renderer
    else {
      // The engine must return the created nodes. See template.engines spec below
      $created = engine($this, target, html)
      // Store the created nodes for future reference. See template.engines spec below
      $this.data(_prev_created, $created)
    }
    // Trigger the template event. Use "templatedata" since ".data" is reserved
    $this.trigger({ type: 'template', templatedata: data, target: $created })
    return $created
  }
  $this.data(_renderer, renderer)
  return $this.data('target') !== false ? renderer(data) : null
}


// $.fn.template.engines is a registry of rendering engines. Each entry is a
// function that accepts 3 parameters:
//    $this: the <script> element
//    target: the target selector or node to render into. May be undefined
//    html: the HTML to render at the target (or around $this if target is missing)
// It returns the created nodes as a jQuery object. This is used in 2 ways:
//    - the template event.target attribute is this return value
//    - $this.data(_prev_created) is set to this return value
template.engines = {}

// The default engine uses jQuery
template.engines['default'] = template.engines['jquery'] = function ($this, target, html) {
  // Parse the template output and create a node collection
  // $.parseHTML ensures that "hello" is parsed as HTML, not a selector
  var $target = $($.parseHTML(html.trim()))
  // If target exists, replace the HTML. Otherwise, create new nodes before the template.
  if (target)
    $(target).html($target)
  else {
    // Remove any previous targets and re-create the output
    var $oldtarget = $this.data(_prev_created)
    if ($oldtarget)
      $oldtarget.remove()
    $this.before($target)
  }
  return $target
}

/* globals morphdom */
template.engines['vdom'] = function ($this, target, html) {
  // If no target is specified, use the previous target, if any
  target = target || $this.data(_prev_created)
  // If a target is specified, wrap the HTML with the target node.
  // For example, <div id="target">...</div> will wrap the HTML with
  // <div id="target"></div>
  var $target, tag_open, tag_close
  if (target) {
    $target = $(target)
    var node = $target.get(0)
    tag_open = '<' + node.nodeName
    $.each(node.attributes, function () {
      tag_open += ' ' + this.name + '=' + this.value
    })
    tag_open += '>'
    tag_close = '</' + node.nodeName + '>'
  }
  // If a target is not specified, Create the target node before the template.
  // Wrap the HTML and the node with <div> to ensure that it's a single node.
  // Morphdom requires a single node.
  else {
    tag_open = '<div>'
    tag_close = '</div>'
    $target = $(tag_open + tag_close).insertBefore($this)
  }
  $target.each(function () {
    morphdom(this, tag_open + html + tag_close)
  })
  return $target
}
