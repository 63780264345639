import { parse } from './url.js'

function array_eq(a, b) {
  return a && b && a.length === b.length && a.every(function (v, i) { return v === b[i] })
}

export function urlchange() {
  var $self = this
  // $self / this is typically a window, but could also be an iframe. Use its location
  var loc = $self.get(0).location
  var oldurl, oldpath, oldhash = {}
  return $self.on('hashchange.urlchange', function () {
    var url = parse(loc.hash.replace(/^#/, ''))
    var change = {}
    var change_query = 0
    // Parse keys in old & new URL hash. Trigger "#?<key>" if a key has changed
    for (var key in $.extend({}, url.searchList, oldhash)) {
      var vals = url.searchList[key] || []
      var old = oldhash[key] || []
      if (!array_eq(vals, old)) {
        var val = vals.length > 0 ? vals[0] : ''
        $self.trigger({ type: '#?' + key, hash: url, vals: vals, old: oldurl }, val)
        oldhash[key] = change[key] = vals
        change_query += 1
      }
    }
    // If the path has changed, trigger a "#/" event
    if (oldpath != url.pathname) {
      $self.trigger({ type: '#/', hash: url, old: oldurl }, url.pathname)
      change['/'] = oldpath = url.pathname
    }
    // If any query has been changed, trigger a "#?" event with all info
    if (change_query)
      $self.trigger({ type: '#?', hash: url, change: change, old: oldurl }, url)
    // If any part of the hash has changed, trigger a "#" event with all info
    if (!$.isEmptyObject(change))
      $self.trigger({ type: '#', hash: url, change: change, old: oldurl }, url)
    oldurl = url
  }).trigger('hashchange')
}
