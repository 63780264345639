// jQuery utilities.
// These are NOT jQuery plugins. They accept a $node directly.
// These are NOT exposed as part of the API. Purely internal.

// Return all values that match the selector
// AMONG and UNDER the $node
export function findall($node, selector) {
  return $node.filter(selector).add($node.find(selector))
}

// Return all values that DO NOT match the selector
// AMONG and UNDER the $node. Complement of findall
export function notall($node, selector) {
  return $node.not(selector).add($node.not(selector))
}

// Returns true if data attribute is present
//    But if the value is "false", "off", "n" or "no" in any case, returns false (like YAML)
// Returns default_value if data attribute is missing
export function hasdata($node, key, default_value) {
  var val = $node.data(key)
  if (typeof val == 'undefined' || val === false || val === null)
    return default_value
  if (typeof val == 'string' && val.match(/^(false|off|n|no)$/i))
    return false
  return true
}

// Return the {width:..., height:...} of the node
export function getSize($node) {
  // Ideally, this is just one line:
  //    return $node.getBoundingClientRect()

  // But see http://stackoverflow.com/q/18153989/100904
  // and https://bugzilla.mozilla.org/show_bug.cgi?id=530985
  // If the contents exceed the bounds of an element,
  // getBoundingClientRect() failes in Firefox.

  // So set display:block, get $().width(), and unset display:block
  $node = $($node)
  var old_display = $node.css('display'),
      result = {}
  if (old_display != 'block')
    $node.css('display', 'block')
  result.width = $node.width()
  result.height = $node.height()
  if (old_display != 'block')
    $node.css('display', old_display)
  return result
}
