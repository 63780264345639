export { version } from './src/package.js'
import { parse, unparse, join, update } from './src/url.js'

export var url = {
  parse: parse,
  unparse: unparse,
  join: join,
  update: update
}

import { urlfilter } from './src/urlfilter.js'

if (typeof jQuery != 'undefined') {
  jQuery.extend(jQuery.fn, {
    urlfilter: urlfilter
  })
}
