// data- attribute to store the last performed search
var _lastsearch_attr = 'search-last'
// data- attribute to store granular search results
var _search_results = 'search-results'

var container_options = {
  selector: '[data-search]',
  hideClass: '',
  showClass: '',
  transform: 'strip',
  change: 'words',
}

export function search(options) {
  var settings = $.extend({}, container_options, options, this.data())
  this
    .off('.g.search')
    .on('keyup.g.search change.g.search', settings.selector, run_search)
    .on('refresh.g.search', refresh)
    .on('search.g.search', function (e) {
      refresh(e)
      run_search(e)
    })
  // If the container *IS* the trigger, run search
  this.filter(settings.selector)
    .on('keyup.g.search change.g.search', run_search)
  return this

  // Extract & transform search strings. Cache in input's dataset.search_results
  // Return the search strings.
  function refresh(e) {
    var opts = $.extend({}, settings, e.target.dataset)
    var search_text = opts.search == '@text' ?
      function (el) { return el.textContent } :
      function (el) { return el.getAttribute(opts.search) }
    var transform = search.transforms[opts.transform]
    var result = $(opts.target).map(function() {
      var s = search_text(this)
      return { el: $(this), original: s, text: transform(s), show: true }
    }).get()
    $(e.target).data(_search_results, result)
      .removeData(_lastsearch_attr)
    return result
  }

  function run_search(e) {
    var opts = $.extend({}, settings, e.target.dataset)
    var $el = $(e.target)
    var out = {
      type: 'shown.g.search',
      searchText: $el.val()
    }
    out.search = search.changes[opts.change](search.transforms[opts.transform](out.searchText))
    var lastsearch = $el.data(_lastsearch_attr)
    if (lastsearch == out.search)
      return
    $el.data(_lastsearch_attr, out.search)

    var hidecls = opts.hideClass,
        showcls = opts.showClass,
        re = new RegExp(out.search || '.*')
    out.results = $el.data(_search_results) || refresh(e)
    out.matches = out.results.length
    out.results.forEach(function (cell) {
      var show = cell.text.match(re)
      if (show !== cell.show) {
        if (hidecls) cell.el[!show ? 'addClass' : 'removeClass'](hidecls)
        if (showcls) cell.el[show ? 'addClass' : 'removeClass'](showcls)
        cell.show = show
      }
      if (!show) out.matches--
    })
    $el.trigger(out)
  }
}

search.transforms = {
  strip: function (s) { return (s || '').toLowerCase().replace(/\s+/g, ' ').replace(/^ /, '').replace(/ $/, '') }
}
search.changes = {
  words: function (s) { return s.replace(/\s+/g, '.*') }
}
