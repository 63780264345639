import { findall } from './_util.js'

var container_options = {
  selector: '[data-toggle="highlight"]',
  target: '.highlight-target',
  mode: 'hover',
  classes: 'active'
}

export function highlight(options) {
  this.each(function() {
    var settings = $.extend({}, container_options, options, this.dataset)
    var container = this
    // Loop through all triggers in the container. This may include the container itself
    findall($(this), settings.selector).each(function () {
      var opts = $.extend({}, settings, this.dataset)
      var $this = $(this).off('.g1.highlight')
      var event = opts.mode == 'click' ? 'click.g1.highlight' : 'mouseenter.g1.highlight mouseleave.g1.highlight'
      $this.on(event, function () {
        // When the trigger is triggered, toggle the target classes and fire a highlight event
        var target = $(opts.target, container).toggleClass(opts.classes)
        $this.trigger({ type: 'highlight', target: target })
      })
    })
  })
  return this
}
