/* This is extracted from

https://github.com/d3/d3-interpolate/blob/master/src/transform/parse.js
https://github.com/d3/d3-interpolate/blob/master/src/transform/decompose.js

TODO: import parseSvg directly from d3 instead of re-writing.

*/
var svgNode
var degrees = 180 / Math.PI
var identity = {translateX: 0, translateY: 0}

export function parseSvg(value) {
  if (value == null) return identity
  if (!svgNode) svgNode = document.createElementNS('http://www.w3.org/2000/svg', 'g')
  svgNode.setAttribute('transform', value)
  if (!(value = svgNode.transform.baseVal.consolidate())) return identity
  value = value.matrix
  return decompose(value.a, value.b, value.c, value.d, value.e, value.f)
}

function decompose(a, b, c, d, e, f) {
  var scaleX, scaleY, skewX
  if (scaleX = Math.sqrt(a * a + b * b)) a /= scaleX, b /= scaleX
  if (skewX = a * c + b * d) c -= a * skewX, d -= b * skewX
  if (scaleY = Math.sqrt(c * c + d * d)) c /= scaleY, d /= scaleY, skewX /= scaleY
  if (a * d < b * c) a = -a, b = -b, skewX = -skewX, scaleX = -scaleX
  return {
    translateX: e,
    translateY: f,
    rotate: Math.atan2(b, a) * degrees,
    skewX: Math.atan(skewX) * degrees,
    scaleX: scaleX,
    scaleY: scaleY
  }
}
