import { namespace } from './namespace_util.js'
import { types } from './types.js'

function isEqual(value, compare_with, criteria_satisfied) {
  // to handle: ( ...Shape!&... ) or ( ...&Shape&... )
  if (!value) {
    return criteria_satisfied ? (compare_with == null) : (compare_with != null)
  }
  return value.indexOf(compare_with) != -1 ? !criteria_satisfied : criteria_satisfied
}

function greater_than(value, compare_with, include_equals) {
  if ((isNaN(compare_with) && Date.parse(compare_with))) {
    compare_with = Date.parse(compare_with)
  }
  if ((isNaN(value) && Date.parse(value))) {
    value = Date.parse(value)
  }
  return include_equals ? (compare_with >= value) : (compare_with > value)
}
var operators = {
  '=': function (value, compare_with) {
    return isEqual(value, compare_with, false)
  },
  '!': function (value, compare_with) {
    return isEqual(value, compare_with, true)
  },
  '>': function (value, compare_with) {
    return greater_than(value, compare_with, false)
  },
  '<': function (value, compare_with) {
    return greater_than(compare_with, value, false)
  },
  '>~': function (value, compare_with) {
    return greater_than(value, compare_with, true)
  },
  '<~': function (value, compare_with) {
    return greater_than(compare_with, value, true)
  },
  '~': function (value, compare_with) {
    return isEqual(compare_with, value[0], false)
  },
  '!~': function (value, compare_with) {
    return isEqual(compare_with, value[0], true)
  }
}

var sorting = {
  'string': function (value, compare_with, order) {
    if (!order) order = 'asc'
    // swap if 'desc'
    if (order == 'desc')
      value = [compare_with, compare_with = value][0]

    return value.localeCompare(compare_with)
  },
  'number': function (value, compare_with, order) {
    if (!order) order = 'asc'
    // swap if 'desc'
    if (order == 'desc')
      value = [compare_with, compare_with = value][0]

    return value - compare_with
  }
}

function clone_pluck(source, include_keys, exclude_keys) {
  if (include_keys.length == 0) include_keys = Object.keys(source)
  var new_obj = {}
  include_keys.forEach(function (key) {
    if (exclude_keys.indexOf(key) < 0) new_obj[key] = source[key]
  })
  return new_obj
}



export function datafilter(data, filters, dataset_name) {
  filters = filters || []

  var result = data
  var operator, value

  // url namespace sanitize
  filters = namespace(filters, dataset_name)

  var data_types = types(data, { convert: true })

  // apply WHERE clause
  for (var key in filters) {
    if (key[0] == '_') continue
    var operator_index = (key.match(/(!|>|>~|<|<~|~|!~)$/)) ? key.match(/(!|>|>~|<|<~|~|!~)$/).index : key.length
    operator = (key.slice(operator_index) != '') ? key.slice(operator_index) : '='
    value = (filters[key][0] != "") ? filters[key] : null

    var col = key.slice(0, operator_index)
    if (data_types[col] == 'number') {
      value = value.map(function(val) { return parseFloat(val)})
    } else if (data_types[col] == 'boolean') {
      value = value.map(function(val){ return String(val) == 'true' ? true : false})
    } else if (data_types[col] == 'date') {
      value = value.map(function(val) { return Date.parse(val) })
    }

    result = result.filter(function (row) {
      return (typeof row[col] != 'undefined') ? operators[operator](value, row[col]) : true
    })
  }

  var offset = parseInt(filters['_offset']) || 0
  var limit = parseInt(filters['_limit']) || 1000

  result = result.slice(offset, (offset + limit))

  // apply SELECT clause
  if (filters['_c']) {
    var exclude_cols = [], include_cols = []
    filters['_c'].forEach(function (column) {
      column[0] == '-' ? exclude_cols.push(column.slice(1)) : include_cols.push(column)
    })
    result = result.map(function (row) {
      return clone_pluck(row, include_cols, exclude_cols)
    })
  }

  if (filters['_sort']) {
    result.sort(function (a, b) {
      var swap_rows = false
      filters['_sort'].forEach(function (sort) {
        var order = (sort[0] == '-') ? 'desc' : 'asc'
        if (sort[0] == '-') sort = sort.substr(1)
        if (typeof a[sort] == 'undefined') return
        // if sort.column evaluates to false, it will proceed with evaluating || expression
        var type = (isNaN(a[sort])) ? 'string' : 'number'
        swap_rows = swap_rows || sorting[type](a[sort], b[sort], order)
      })
      return swap_rows
    })
  }

  return result
}
