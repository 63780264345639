/*
## scale

Converts a configuration into a d3 scale function. This is used to extract a
different value for each data point. For example:

```js
var color_scale = g1.scale({
  metric: 'age',
  scheme: 'RdYlGn'
}, data)
// Returns a color scale that maps the age column to the red - yellow - green color scheme
```

### scale attributes

- metric can be one of:
  - string: column name
  - `function(d, i)` that returns a value for each item in the data
- scheme: color scheme to interpolate to
- scale: d3 scale to use. Defaults to linear
- range: set the range of the scale
- domain: override the domain (which defaults to the extent of the data metric)

NEW SPEC:
- scale: 'linear' or 'quantile'
- domain: {metric: 'col_name'}
- range: for array or {scheme: 'Blues', count: 6}


TODO:
- Support multi-metric domains
- Support order_by on aggregate of metrics
- Support clamp, round for quantitative scales
- Support nice
*/

import uniq from 'lodash-es/uniq'
import upperFirst from 'lodash-es/upperFirst'
import iteratee from 'lodash-es/iteratee'
import { extent } from 'd3-array'


var scale_types = {
      'Ordinal': 'discrete',
      'Band': 'discrete',
      'Point': 'discrete',
      'Linear': 'continuous',
      'Log': 'continuous',
      'Pow': 'continuous',
      'Sqrt': 'continuous',
      'Sequential': 'continuous',
      'Quantile': 'quantile', // Exception because domain is entire dataset
      'Quantize': 'discretizing',
      'Threshold': 'discretizing'
    },
    renames = { Sequential: 'Linear' },
    domain_function = {
      discrete: uniq,
      quantile: d => d,
      discretizing: extent,
      continuous: extent
    }

function backward_compat(config, _scale) {
  if (config.scheme && !Array.isArray(config.range)) {
    config.range = { scheme: config.scheme }
    if (scale_types[_scale] != 'continuous' && config.count) config.range.count = config.count
  }
  if (!config.domain) config.domain = { metric: config.metric }
  config.metric = config.domain.metric || config.metric
  return config
}

function get_domain(data, config_domain, metric, scale) {
  return Array.isArray(config_domain) && scale != 'Quantile' ? config_domain
    : domain_function[scale_types[scale]](data.map(iteratee(metric)))
}

function get_range(config_range, _scale) {
  return Array.isArray(config_range) ? config_range
    : scale_types[_scale] == 'continuous' ? [0, 1]
      : get_colors_from_scheme(get_scheme(config_range.scheme), config_range.count)
}

function flip(array, reverse) {
  return reverse ? array.slice().reverse() : array
}

function get_scheme(scheme) {
  return d3[scheme.startsWith('scheme') ? scheme : 'scheme' + upperFirst(scheme)]
}

function get_colors_from_scheme(scheme, count) {
  // For discrete colors ranges, like schemeBlues, k value is [3, 9]. So, the first 3 values are empty
  return Object.values(scheme).length !== scheme.length ? scheme[count ? count : scheme.length - 1] : scheme
}

function scale(data, config) {
  var _scale = upperFirst(config.scale || 'Linear')

  config = backward_compat(config, _scale)

  var result = d3['scale' + (renames[_scale] || _scale)]()
    .domain(get_domain(data, config.domain, config.metric, _scale))
    .range(flip(get_range(config.range, _scale), config.range.reverse === true || config.reverse))

  return (scale_types[_scale] == 'continuous' && !Array.isArray(config.range)) ?
    (val) => d3['interpolate' + config.range.scheme](result(typeof val === 'object' ? iteratee(config.metric)(val) : val))
    : (val) => result(typeof val === 'object' ? iteratee(config.metric)(val) : val)
}

export { scale }
