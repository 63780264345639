export function ajaxchain(request, $self, requests, responses) {
  $self = $self || $('<div>')
  requests = requests || []
  responses = responses || []
  if (!('limit' in request))
    request.limit = 10
  request.limit--
  $.ajax(request)
    .done(function (response, status, xhr) {
      requests.push(request)
      responses.push(response)
      $self.trigger({ type: 'load', request: request, response: response, xhr: xhr })
      if (request.chain && request.limit > 0) {
        try {
          var updates = request.chain(response, request, xhr)
        } catch (e) {
          $self.trigger({ type: 'error', request: request, xhr: xhr, exception: e })
          // eslint-disable-next-line no-console
          console.warn('$.ajaxchain: chain() exception', e)
        }
        if ($.isPlainObject(updates) && !$.isEmptyObject(updates)) {
          var new_request = $.extend(true, {}, request, updates)
          var next = ajaxchain(new_request, $self, requests, responses)
        }
      }
      if (!next)
        $self.trigger({ type: 'done', request: requests, response: responses })
    })
    .fail(function (xhr, testStatus, error) {
      $self.trigger({ type: 'error', request: request, xhr: xhr })
      $self.trigger({ type: 'done', request: requests, response: responses })
      // eslint-disable-next-line no-console
      console.warn('$.ajaxchain: ajax error', error)
    })
  return $self
}

// Chain through a list of URLs in order
ajaxchain.list = function (urls) {
  return function (response, request) {
    var next = urls.indexOf(request.url) + 1
    if (next < urls.length)
      return { url: urls[next] }
  }
}

// Used by Twitter, YouTube
// Google:    .cursor('data.pageToken', 'nextPageToken')
// Twitter:   .cursor('data.cursor', 'next_cursor')
// Facebook:  .cursor('url', 'paging.next')
if (typeof _ == 'undefined')
  ajaxchain.cursor = function () {
    throw new Error('ajaxchain.cursor requires lodash')
  }
else
  ajaxchain.cursor = function (target, source) {
    return function (response) {
      var key = _.get(response, source)
      if (key)
        return _.set({}, target, key)
    }
  }
