import { parse } from './url.js'
import { hasdata } from './_util.js'

var container_options = {
  attr: 'href',
  event: 'click change submit',
  selector: '.urlfilter',
  src: 'src'
}
var event_namespace =  '.urlfilter'

export function urlfilter(options) {
  var $self = this
  // If there are no elements in the selection, exit silently
  if ($self.length == 0)
    return
  var doc = $self[0].ownerDocument
  var settings = $.extend({}, container_options, options || {}, this.dataset)
  var default_remove = settings.remove || hasdata($self, 'remove')
  var events = settings.event.split(/\s+/).map(function (v) { return v + event_namespace }).join(' ')
  // options.location and options.history are used purely for testing
  var loc = settings.location || (doc.defaultView || doc.parentWindow).location
  var hist = settings.history || (doc.defaultView || doc.parentWindow).history
  return $self
    .on(events, settings.selector, function (e) {
      var $this = $(this),
          mode = $this.data('mode') || settings.mode,
          target = $this.data('target') || settings.target,
          src = $this.data('src') || settings.src,
          remove = hasdata($this, 'remove', default_remove),
          href
      if (e.type == 'change' || $(e.target).is(':input:not(:button)')) {
        var key = encodeURIComponent($this.attr('id') || $this.attr('name'))
        var val = encodeURIComponent($this.val())
        href = '?' + key + '=' + val
      }
      else if (e.type == 'submit' || $(e.target).is('form')) {
        e.preventDefault()
        href = '?' + $this.serialize()
      }
      else if (e.type == 'click') {
        if ($(e.target).is('a'))
          e.preventDefault()
        href = $this.attr(settings.attr)
      } else
        return

      var url = parse(href),
          q = url.searchList

      function target_url(url) {
        var result = parse(url)
          .join(href, { query: false, hash: false })
          .update(q, mode)
        if (remove) {
          var missing_keys = {}
          for (var key in result.searchKey)
            if (result.searchKey[key] === '')
              missing_keys[key] = null
          result.update(missing_keys)
        }
        return result.toString()
      }

      // If the target is...       the URL is get/set at
      // ------------------------  ---------------------
      // unspecified (=> window)   location.href
      // 'pushState'               location.href
      // '#'                       location.hash
      // anything else             $(target).data(src)
      if (!target)
        loc.href = target_url(loc.href)
      else if (target == '#')
        loc.hash = target_url(loc.hash.replace(/^#/, ''))
      else if (target.match(/^pushstate$/i))
        hist.pushState({}, '', target_url(loc.href))
      else {
        $(target).each(function () {
          var $target = $(this)
          var url = target_url($target.attr(src))
          $target.attr(src, url).load(url, function () {
            $target.trigger({ type: 'load', url: url })
          })
        })
      }
      $this.trigger({ type: 'urlfilter', url: url })
    })
}
