var _event
try {
  new Event('click')
  _event = function(name, options) {
    // On Firefox, you needed to send the right event subclass. This is no longer a problem.
    // See https://developer.mozilla.org/en-US/docs/Web/Reference/Events for the list
    // if (name.match(/click$|^mouse|^menu$/)) return new MouseEvent(name, options)
    // else if (name.match(/^key/))            return new KeyboardEvent(name, options)
    // else if (name.match(/^focus|^blur$/))   return new FocusEvent(name, options)
    return new Event(name, options)
  }
} catch (e) {
  // The old fashioned way, for IE
  _event = function(name, options) {
    var evt = document.createEvent('event')
    evt.initEvent(name, options.bubbles, options.cancelable)
    return evt
  }
}

export function dispatch(name, options) {
  return this.each(function() {
    this.dispatchEvent(_event(name, $.extend({
      bubbles: true,
      cancelable: true
    }, options)))
  })
}
