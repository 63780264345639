export function translate(options) {
  options = options || {}
  var self = this
  self.each(function () {
    var $this = $(this)
    var source = $this.attr('lang') || options.source || ''
    var target = $this.attr('lang-target') || options.target
    if (!target)
      throw new Error('$.translate has no target')
    if (target == source)
      return
    var nodes = [], original = [], q = [], node
    var walk = document.createTreeWalker(this, NodeFilter.SHOW_TEXT, null, false)
    while (node = walk.nextNode()) {
      var text = node.textContent
      var trimmed = $.trim(text)
      if (trimmed) {
        nodes.push(node)
        original.push(text)
        q.push(trimmed)
      }
    }
    var request = { q: q, source: source, target: target }
    $.ajax({
      url: $this.attr('lang-url') || options.url || 'translate',
      data: request,
      traditional: true
    }).done(function (response) {
      response.forEach(function (d, i) {
        d.node = nodes[i]
        d.node.textContent = original[i].replace(d.q, d.t)
      })
      $this.trigger({ type: 'translate', translate: response })
    }).fail(function (xhr, testStatus, error) {
      $this.trigger({ type: 'error', request: request, xhr: xhr })
      // eslint-disable-next-line no-console
      console.warn('$.translate: error', error)
    })
  })
  return this
}
