/*
  var search = g1.fuzzysearch(data, options)
  // Specify keys in data as a list of column names or functions
  options.keys = [ 'col1', function(v) { return v.info.name } ]
  // Specify max results to return. Default: 100
  options.limit = 10
  // Case sensitive search. Default: false
  options.case = True
*/
export function fuzzysearch (data, options) {
  options = options || {}
  // "values" is the string array with the text to search
  var values
  // If no options.keys are provided, use the raw data as-is
  if (!options.keys)
    values = data
  // Else, join the provided keys
  else
    values = data.map(function(row) {
      return options.keys.map(function(v) {
        return typeof v == 'function' ? v(row) : row[v]
      }).join(' ')
    })
  var limit = options.limit || 100
  var flags = options.case ? '' : 'i'
  // TODO: document these options once stabilized
  var depth = options.depth || 10
  var escape = options.escape || true

  return function(text) {
    var results = [],             // Final results
        vals = values.slice(),    // Values to search. Crosses off matches to avoid duplication
        re
    // Trim the search text
    text = text.replace(/^\s/, '').replace(/\s$/, '')
    if (escape)
      text = text.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')

    // 1. Match full phrase
    re = new RegExp(text.replace(/\s+/, '\\s+'), flags)
    vals.forEach(function (v, i) {
      if (v && re.test(v)) { results.push(data[i]); vals[i] = '' }
    })
    if (depth <= 1 || results.length >= limit) return results.slice(0, limit)

    // 2. Match words in order
    re = new RegExp(text.replace(/\s+/, '.*'), flags)
    vals.forEach(function (v, i) {
      if (v && re.test(v)) { results.push(data[i]); vals[i] = '' }
    })
    if (depth <= 2 || results.length >= limit) return results.slice(0, limit)

    // 3. Match words in any order
    re = text.split(/\s+/).map(function (word) { return new RegExp(word, flags) })
    vals.forEach(function (v, i) {
      if (v && re.every(function (word) { return word.test(v) })) { results.push(data[i]); vals[i] = '' }
    })
    if (depth <= 3 || results.length >= limit) return results.slice(0, limit)

    // 4. Match partial words in any order
    re = text.split(/\s+/).map(function (word) { return new RegExp(word.replace(/(.)/g, '$&[\\S]*'), flags) })
    vals.forEach(function (v, i) {
      if (v && re.every(function (word) { return word.test(v) })) { results.push(data[i]); vals[i] = '' }
    })

    // 5. Match characters in order
    re = new RegExp(text.replace(/(.)/g, '$&.*'), flags)
    vals.forEach(function (v, i) {
      if (v && re.test(v)) { results.push(data[i]); vals[i] = '' }
    })
    if (depth <= 4 || results.length >= limit) return results.slice(0, limit)

    return results.slice(0, limit)
  }
}
