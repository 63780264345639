export { version } from './src/package.js'
import { formhandler } from './src/formhandler.js'
// FormHandler requires $().urlfilter
export { url } from './index-urlfilter.js'

if (typeof jQuery != 'undefined') {
  jQuery.extend(jQuery.fn, {
    formhandler: formhandler
  })
}
