import * as default_templates from './dropdown.template.html'
import deepmerge from 'deepmerge'

var default_options = {
  target: '',
  multiple: false,
  value_key: 'value',
  label_key: 'label'
}

export function dropdown(js_options) {
  var self = $(this)
  var options = deepmerge(default_options, js_options)

  self.html(_.template(default_templates['template_dropdown'])(options))

  if (options.data) {
    render(options.data)
    // Set the default
    if (options.value)
      self.find('.selectpicker').val(options.value).selectpicker('refresh')
    // trigger 'load' event
    self.trigger({type: 'load'})
  }
  else if (options.url) {
    render(['Loading...'])
    $.ajax(options.url)
      .fail(function(xhr, status, message) {
        // load error template
        self.html(_.template(default_templates['template_error'])({message: message}))
      })
      .done(function(response) {
        render(response)
        self.find('.selectpicker').selectpicker('refresh')
        self.trigger({type: 'load'})
      })
  }

  function render(data) {
    options.data = data
    if (options.key) {
      // urlfilter
      self.urlfilter({
        selector: 'select.urlfilter',
        target: options.target,
        event: 'change',
        remove: true
      })
    }
    // re-render dropdown template options with fetched data
    var template = typeof data[0] == 'object' ? 'template_dropdown_object' : 'template_dropdown_options'
    self.find('.selectpicker')
      .html(_.template(default_templates[template])(options))
      .selectpicker(options.options)
  }

  return this
}
