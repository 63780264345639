export function namespace(search, name) {
  // Return an object with all keys in search that begin with `<name>:` or
  // do not have a `:` in them.
  // If name is false-y, return search
  if (!name)
    return search
  var result = {}
  for (var key in search) {
    var parts = key.split(':')
    if (parts.length == 1)
      result[parts[0]] = search[key]
    else if (parts[0] === name)
      result[parts[1]] = search[key]
  }
  return result
}
