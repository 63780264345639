// state_transition[old_state][current_type] -> new_state
// type: undefined and type: null are mapped to state 'null', i.e. missing values
var state_transitions = {
  'null': {
    'null': { state: 'null' },
    'date': { state: 'date' },
    'number': { state: 'number' },
    'boolean': { state: 'boolean' },
    'string': { state: 'string' },
    'object': { state: 'object' },
    'mixed': { state: 'mixed', end: true }
  },
  'date': {
    'null': { state: 'date' },
    'undefined': { state: 'date' },
    'date': { state: 'date' },
    'default': { state: 'mixed', end: true }
  },
  'number': {
    'null': { state: 'number' },
    'undefined': { state: 'number' },
    'number': { state: 'number' },
    'default': { state: 'mixed', end: true }
  },
  'boolean': {
    'null': { state: 'boolean' },
    'undefined': { state: 'boolean' },
    'boolean': { state: 'boolean' },
    'default': { state: 'mixed', end: true }
  },
  'string': {
    'null': { state: 'string' },
    'undefined': { state: 'string' },
    'string': { state: 'string' },
    'default': { state: 'mixed', end: true }
  },
  'object': {
    'null': { state: 'object' },
    'object': { state: 'object' },
    'undefined': { state: 'object' },
    'default': { state: 'mixed', end: true }
  },
  'mixed': {
    'default': { state: 'mixed', end: true }
  }
}


export function types(data, options) {
  var result = {}
  if (!data || !data.length)
    return result

  options = options || {}
  options.convert = options.convert || false
  options.limit = options.limit || 1000
  var limit = (options.limit < data.length) ? options.limit : data.length
  var ignore = options.ignore = options.ignore || [null, undefined]
  var columns = Object.keys(data[0])

  for (var columnIndex = 0; columnIndex < columns.length; columnIndex++) {
    var column = columns[columnIndex]
    var result_type = 'null'
    for (var index = 0; index < limit; index++) {
      var row = data[index]
      var value = row[column]

      if (columnIndex == 0) {
        Object.keys(data[index]).forEach(function (value) {
          if (columns.indexOf(value) == -1)
            columns.push(value)
        })
      }

      // Ignore if the value is missing
      if (!(column in row))
        continue
      // Ignore values that are in the ignore list
      if (ignore.indexOf(value) >= 0)
        continue
      // Identify type (date, object, number, boolean, string, undefined, null)
      var type = typeof value
      if (value === undefined || value === null)
        type = 'null'
      else if (type == 'object' && !isNaN(Date.parse(value)))
        type = 'date'
      else if (options.convert) {
        // We use parseFloat AND isFinite because
        // parseFloat('2018-01') is 2018 but isFinite('2018-01') is false
        // Also, 'NaN', 'Infinity' and '-Infinity' should be treated as numbers
        if ((!isNaN(parseFloat(value)) && isFinite(value)) || ['NaN', 'Infinity', '-Infinity'].indexOf(value) >= 0)
          type = 'number'
        else if (!isNaN(Date.parse(value)))
          type = 'date'
        else if (['true', 'false'].indexOf(value) != -1)
          type = 'boolean'
      }

      // Apply the state change
      var state_transition = state_transitions[type]
      var change = state_transition[result_type] || state_transition['default']
      result_type = change['state']
      if (change['end'])
        break
    }
    result[column] = result_type
  }
  return result
}
