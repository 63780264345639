import { scale } from './scale.js'
import { parseSvg } from './parsetransform.js'
export { sanddance }

function SanddanceException(message, options) {
  this.message = message
  this.options = options
}
function pop(dict, key, value) {
  if (key in dict) {
    value = dict[key]
    delete dict[key]
  }
  return value
}
// A replacement for Object.assign()
function copy(target) {
  for (var i=1, l=arguments.length; i<l; i++) {
    var source = arguments[i]
    for (var key in source)
      target[key] = source[key]
  }
  return target
}
// Distance between two points
function dist(x1, y1, x2, y2) {
  var dx = x2 - x1
  var dy = y2 - y1
  return Math.sqrt(dx * dx + dy * dy)
}
// Return the indices of data when data is sorted by a column (str or fn),
// with order specified by ascending=true (default) or false
function sort(data, column, ascending) {
  // indices[i] will give us the sorted position of the i-th item
  var indices = data.map(function (d, i) { return i })
  if (column) {
    // items is a list of [sort_value, index] which we use for a stable sort
    var items = data.map(typeof column == 'function' ?
      function (d, i) { return [column(d), i] } :
      function (d, i) { return [d[column], i] }
    )
    // sort the indices using a stable sort
    indices.sort(function (a, b) {
      var val1 = items[a][0],
          val2 = items[b][0]
      return val1 < val2 ? -1 : val1 > val2 ? +1 : items[a][1] - items[b][1]
    })
    // invert the indices in ascending or descending order
    ascending = !ascending && typeof ascending != 'undefined'
    for (var i = 0, l = indices.length, inv = []; i < l; i++)
      inv[indices[i]] = ascending ? i : l - i - 1
    indices = inv
  }
  return indices
}


// If an options value is an object, treat it as a scale configuration.
// Replace it with the scale function
function update_scale(data, options) {
  for (var key in options)
    if (typeof options[key] == 'object')
      options[key] = scale(data, options[key])
  return options
}

var namespace = '.sanddance'
var layout_map = {
  grid: sanddance_grid,
  hexpack: sanddance_hexpack,
  spiral: sanddance_spiral
}

function sanddance(attrs, options) {
  options = options || {}
  var dispatch = d3.dispatch('init', 'start', 'end')
  var layout_props
  if (options.layout) {
    layout_props = layout_map[options.layout](attrs, options)
    attrs = layout_props.attrs
    options = layout_props.options
  }
  var duration = options.duration
  var speed = options.speed
  var delay = options.delay || 0
  var easing = options.easing || d3.easeLinear
  var filter = options.filter
  var x = options.x
  var y = options.y

  var result = function (selection) {
    dispatch.call('init', selection)
    var filtered = filter ? selection.filter(filter) : selection

    var transition = filtered.transition()
      .ease(easing)

    if (x || y) {
      var x_fn = typeof x == 'function' ? x : function () { return x }
      var y_fn = typeof y == 'function' ? y : function () { return y }
      transition.attr('transform', function(d, i) {
        var x = x_fn(d, i)
        var y = y_fn(d, i)
        this.dataset['_sd_x'] = x
        this.dataset['_sd_y'] = y
        return 'translate(' + x + ',' + y + ')'
      })
    }

    for (var key in attrs)
      transition = transition.attr(key, attrs[key])

    // Note: duration = 0 is a valid duration
    if ('duration' in options)
      transition = transition.duration(duration)
    else if (speed)
      transition = transition.duration(function (d) {
        var transform = parseSvg(this.getAttribute('transform'))
        var x1 = transform.translateX
        var y1 = transform.translateY
        // TODO (Anand): Need a better way of identifying where the element will be
        // across transforms and setting x, y
        var x2 = this.dataset['_sd_x']
        var y2 = this.dataset['_sd_y']
        var distance = dist(x1, y1, x2, y2)
        var _speed = typeof speed == 'function' ? speed(d) : speed
        return distance / _speed * 1000
      })

    if (delay)
      transition = transition.delay(function (d) {
        return typeof delay == 'function' ? delay(d) : delay
      })

    // Set up event handling.
    // sanddance.on('start') is triggered when the first transition begins
    var count_end = filtered.size(),
        count_start = 0
    transition.on('start.count', function () {
      if (count_start === 0)
        dispatch.call('start', selection)
      count_start++
    })
    // sanddance.on('end') is triggered when the last transition stops (ends / interrupted)
    transition.on('end interrupt', function () {
      count_end--
      if (count_end > 0)
        return
      else if (count_end === 0)
        return dispatch.call('end', selection)
      else
        throw new SanddanceException('sanddance: Invalid count: ' + count_end, options)
    })
  }
  result.on = function (event, callback) {
    dispatch.on(event + namespace, callback)
    return result
  }
  // Returns a new sanddance with updated attributes
  result.update = function (update_attrs, update_options) {
    return sanddance(copy({}, attrs, update_attrs), copy({}, options, update_options))
  }
  return result
}

sanddance.chain = function () {
  for (var i = 0, l = arguments.length - 1; i < l; i++) {
    (function (prev, next) {
      prev.on('end' + namespace, function () { this.call(next) })
    })(arguments[i], arguments[i + 1])
  }
  return arguments[0]
}

function sanddance_grid (attrs, options) {
  var data = options.data
  var width = options.width
  var height = options.height
  var area = width * height / data.length
  var element_width = Math.sqrt(area)
  var elements_per_row = Math.ceil(width / element_width)
  var num_rows = Math.ceil(data.length / elements_per_row)
  var element_height = element_width * num_rows
  var filter = options.filter
  data = filter ? data.filter(function (d, i) { return filter(d, i) }) : data
  var indices = sort(data, options.sort, options.ascending)

  attrs.transform = function (d, i) {
    // TODO: pre-compute these into a pos[] array
    i = indices[i]
    var col = i % elements_per_row
    var row = Math.floor(i / elements_per_row)
    var x = this.dataset['_sd_x'] = col * element_width
    var y = this.dataset['_sd_y'] = element_height - ((row + 1) * element_width)
    return 'translate(' + x + ',' + y + ')'
  }
  return {attrs: update_scale(data, attrs), options: options}
}

function sanddance_hexpack (attrs, options) {
  var data = options.data
  var filter = options.filter
  data = filter ? data.filter(function (d, i) { return filter(d, i) }) : data
  var indices = sort(data, options.sort, options.sort_ascending)
  var count = data.length
  var pos = [[0, 0]],
      l = 1,    // level
      n = 0,    // number of items filled so far
      i
  while (n < count) {
    for (i = 0;  i >= 1 - l; i--) pos.push([l, i])       // top-right
    for (i = l;  i >= 1;     i--) pos.push([i, -l])      // top
    for (i = 0;  i >= 1 - l; i--) pos.push([i, -l - i])  // top left
    for (i = 0;  i <= l - 1; i++) pos.push([-l, i])      // bottom left
    for (i = -l; i <= -1;    i++) pos.push([i, l])       // bottom
    for (i = 0;  i <= l - 1; i++) pos.push([i, l - i])   // bottom right
    n += l * 6    // increment number of items filled
    l++           // move to next level
  }
  // Pre-compute positions
  var width = options.width
  var height = options.height
  var diameter = 2 * l - 1
  var size_x = width / diameter
  var size_y = height / diameter
  var cos = size_x * Math.cos(Math.PI * 60 / 180)
  var sin = size_y * Math.sin(Math.PI * 60 / 180)
  pos = pos.slice(0, count).map(function (row) {
    return [row[0] * size_x + row[1] * cos + width / 2, row[1] * sin + height / 2]
  })
  attrs.transform = function (d, i) {
    i = indices[i]
    var p = pos[i]
    return 'translate(' + p[0] + ',' + p[1] + ')'
  }
  return {attrs: update_scale(data, attrs), options: options}
}

function sanddance_spiral (attrs, options) {
  var data = options.data
  var filter = options.filter
  data = filter ? data.filter(function (d, i) { return filter(d, i) }) : data
  var indices = sort(data, options.sort, options.sort_ascending)
  var radius = pop(options, 'spiral_size')
  var angle = pop(options, 'spiral_angle') * Math.PI / 180
  var pos = data.map(function (d, i) {
    return [
      (i * radius) * Math.cos(i * angle),
      (i * radius) * Math.sin(i * angle)
    ]
  })
  attrs.transform = function (d, i) {
    i = indices[i]
    return 'translate(' + pos[i][0] + ',' + pos[i][1] + ')'
  }
  return {attrs: update_scale(data, attrs), options: options}
}
