// Import HTML sections as variables using rollup-plugin-htmlparts.js.
// See rollup.config.js for usage.
// Each <!-- var name --> section is imported as a minified variable string.
import * as default_templates from './formhandler.template.html'
import { parse } from './url.js'
import { namespace } from './namespace_util.js'
import { datafilter } from './datafilter.js'

// Render components in this order. The empty component is the root component.
var components = ['', 'table', 'edit', 'add', 'page', 'size', 'count', 'export', 'filters', 'error', 'table_grid']
var default_options = {
  table: true,
  edit: false,
  add: false,
  page: true,
  pageSize: 100,
  size: true,
  sizeValues: [10, 20, 50, 100, 500, 1000],
  count: true,
  export: true,
  exportFormats: {
    xlsx: 'Excel',
    csv: 'CSV',
    json: 'JSON',
    html: 'HTML'
  },
  filters: true,
  onhashchange: true
}
var meta_headers = ['filters', 'ignored', 'excluded', 'sort', 'offset', 'limit', 'count']

var default_filters = {
  text: { '': 'Equals...', '!': 'Does not equal...', '~': 'Contains...', '!~': 'Does not contain...' },
  number: { '': 'Equals...', '!': 'Does not equal...', '<': 'Less than...', '>': 'Greater than...' },
  date: { '': 'Equals...', '!': 'Does not equal...', '<': 'Before...', '>': 'After...' }
}

// Set default values for column specifications
// function col_defaults(colinfo, data) {
function col_defaults(colinfo) {
  // Sort defaults
  if (!('sort' in colinfo) || colinfo.sort === true)
    colinfo.sort = { '': 'Sort ascending', '-': 'Sort descending' }
  else if (typeof colinfo.sort != 'object')
    colinfo.sort = {}

  // Type defaults
  colinfo.type = colinfo.type || 'text'

  // Filters defaults
  if (!('filters' in colinfo) || (colinfo.filters === true))
    colinfo.filters = default_filters[colinfo.type]

  // Hideable defaults
  if (!('hideable' in colinfo))
    colinfo.hideable = true

  // Hide defaults
  if (!('hide' in colinfo))
    colinfo.hide = false
}


export function formhandler(js_options) {
  if (!js_options)
    js_options = {}

  this.each(function () {
    var $this = $(this)
    // Convert all .urlfilter classes into url filters that update location.hash
    $this.urlfilter({
      selector: '.urlfilter, .page-link',
      target: '#',
      remove: true                          // auto-remove empty values
    })

    // Pre-process options
    var options = $.extend({}, default_options, js_options, $this.data())

    if (!options.columns)
      options.columns = []
    else if (typeof options.columns == 'string')
      options.columns = _.map(options.columns.split(/\s*,\s*/), function (col) { return { name: col } })

    // Compile all templates
    var template = {}
    _.each(components, function (name) {
      var tmpl = options[name ? name + 'Template' : 'template'] || default_templates['template_' + name] || 'NA'
      template[name] = _.template(tmpl)
    })

    function draw_table(data, args, meta) {
      // Add metadata
      meta.rows = data.length
      meta.columns = data.length ? _.map(data[0], function (val, col) { return { name: col } }) : []

      // If any column name is '*', show all columns
      var star_col = _.find(options.columns, function (o) { return o['name'] === '*' })
      if (star_col) {
        var action_header_cols = _.cloneDeep(meta.columns)
        _.map(options.columns, function (option_col) {
          var found = _.find(meta.columns, function (o) { return o['name'] === option_col.name })
          if (!found && option_col.name !== '*')
            action_header_cols.push(option_col)
        })

        action_header_cols = _.map(action_header_cols, function (col) {
          var options_col = _.find(options.columns, function (o) { return o['name'] === col.name })
          return options_col ? options_col : $.extend({}, star_col, col)
        })
      }

      options.columns = action_header_cols ? action_header_cols : options.columns

      // Render all components into respective targets
      var template_data = {
        data: data,
        meta: meta,
        args: args,
        options: options,
        idcount: 0,
        parse: parse,
        col_defaults: col_defaults,
        isEdit: false,
        isAdd: false,
        templates: default_templates,
        notify: notify.bind(this, $this, template),
        failHandler: failHandler.bind(this, $this, template)
      }
      // Store template_data in $this
      $this.data('formhandler', template_data)

      _.each(components, function (name) {
        render_template(name, template_data, options, $this, template)
      })
      if (options.add)
        addHandler($this, template_data, options, template)
      if (options.edit)
        editHandler($this, template_data, options, template)
    }

    function render() {
      var url_args = parse(location.hash.replace(/^#/, '')).searchList
      url_args = namespace(url_args, options.name)
      // Create arguments passed to the FormHandler. Override with the user URL args
      var args = _.extend({
        c: options.columns.map(function (d) { return d.name }),
        _limit: options.pageSize,
        _format: 'json',
        _meta: 'y'
      }, url_args)
      $('.loader', $this).removeClass('d-none')

      function done(data, status, xhr) {
        var meta = {}
        _.each(meta_headers, function (header) {
          var val = xhr ? xhr.getResponseHeader('Fh-Data-' + header) : null
          if (val !== null)
            meta[header] = JSON.parse(val)
        })
        if (typeof options.transform == 'function') {
          var result = options.transform({ data: data, meta: meta, options: options, args: args }) || {}
          data = 'data' in result ? result.data : data
          meta = 'meta' in result ? result.meta : meta
        }

        // To support data-src that doesn't poin to formhandler url pattern
        if (_.isEmpty(meta) && options.page && options.size) {
          meta['offset'] = args._offset ? parseInt(args._offset) : 0
          meta['limit'] = parseInt(args._limit)
          meta['count'] = data.length
          data = datafilter(data, args)
        }

        draw_table(data, args, meta)
        $this.trigger({ type: 'load', formdata: data, meta: meta, args: args, options: options })
      }

      if (options.data && typeof (options.data) == 'object') {
        options.edit = false
        options.add = false
        done(options.data)
      }
      else {
        $.ajax(options.src, {
          dataType: 'json',
          data: args,
          traditional: true
        }).done(done)
          .always(function () { $('.loader', $this).addClass('d-none') })
          .fail(failHandler.bind(this, $this, template))
      }
    }

    modalHandler($this)

    actionHandler($this, options, template)

    // Re-render every time the URL changes
    if (options.onhashchange)
      $(window).on('hashchange', render)
    // Initialize
    render()
  })

  return this
}

function modalHandler($this) {
  //  Handle modal dialog
  $this
    .on('shown.bs.modal', '.formhandler-table-modal', function (e) {
      var $el = $(e.relatedTarget)
      var template_data = $this.data('formhandler')
      var op = $el.data('op')
      var col = $el.closest('[data-col]').data('col')
      var val = ''
      // If there is a value, show it, and allow user to remove the filter
      if (template_data.args[col + op]) {
        val = template_data.args[col + op].join(',')
        $('.remove-action', this).attr('href', '?' + col + op + '=').show()
      } else
        $('.remove-action', this).hide()
      $('input', this).val(val).attr('name', col + op).focus()
      $('label', this).text($el.text())
    })
    .on('submit', 'form', function (e) {
      e.preventDefault()
      var filter = parse('?' + $(this).serialize()).searchKey
      $(this).closest('.formhandler-table-modal').modal('hide')
      window.location.hash = '#' + parse(location.hash.replace(/^#/, '')).update(filter)
    })
}

function render_template(name, data, options, $this, template) {
  // Disable components if required. But root component '' is always displayed
  if (name && !options[name])
    return

  var target
  // The root '' component is rendered into $this.
  if (!name)
    target = $this
  else {
    // Rest are rendered into .<component-name> under $this

    if (options[name] == 'grid') name = 'table_grid'
    var selector = options[name + 'Target'] || '.' + name
    target = $(selector, $this)
    // But if they don't exist, treat the selector as a global selecctor
    if (target.length == 0)
      target = $(selector)
  }
  target.html(template[name](data))
}


function addHandler($this, template_data, options, template) {

  $('.add button', $this)
    .on('click', function () {
      var add_btn = $('.add button', $this)
      var edit_btn = $('.edit button', $this)
      if (add_btn.html().toLowerCase() == 'save') {
        add_btn.html('Add')
        edit_btn.prop('disabled', false)
        var columns_data = $('.new-row td[data-key]')

        $('.loader', $this).removeClass('d-none')
        var data = {}

        $.each(columns_data, function (key, column) {
          data[column.getAttribute('data-key')] = $(column).children().val()
        })
        // if no changes made to new empty celled row, rerender the table.
        if (!_.some(data)) {
          $('.loader', $this).addClass('d-none')
          template_data.isAdd = false
          render_template('table', template_data, options, $this, template)
          return
        }

        $.ajax(options.src, {
          method: 'POST',
          dataType: 'json',
          data: data
        }).done(function () {
          template_data.data.unshift(data)
          template_data.isAdd = false
          render_template('table', template_data, options, $this, template)
          if (options.add.done) options.add.done()
        }).always(function () { $('.loader', $this).addClass('d-none') })
          .fail(failHandler.bind(this, $this, template))
      } else if (add_btn.html().toLowerCase() == 'add') {
        add_btn.html('Save')
        edit_btn.prop('disabled', true)
        template_data.isAdd = true
        render_template('table', template_data, options, $this, template)
        add_edit_events($this, add_btn)
      }
    })
}

function editHandler($this, template_data, options, template) {
  $('.edit button', $this)
    .on('click', function () {
      var edit_btn = $('.edit button', $this)
      var add_btn = $('.add button', $this)
      if (edit_btn.html().toLowerCase() == 'save') {
        var edited_rows = $('.edited-row')
        if (edited_rows.length > 0)
          $('.loader', $this).removeClass('d-none')

        var all_ajax = []
        var allRowsValid = true
        $.each(edited_rows, function (key, edited_row) {
          var data = JSON.parse(edited_row.getAttribute('data-val'))
          var rowIndex = edited_row.getAttribute('data-row')
          for (key in data) {
            // TODO: refactor to identify editable columns other than using data-key attrs on <td> tag
            $('td[data-key="' + (remove_quotes(key)) + '"] :input', edited_row).each(function() {
              if (this.checkValidity()) {
                $(this).removeClass('is-invalid')
                data[key] = template_data['data'][rowIndex][key] = $(this).val()
              } else {
                $(this).addClass('is-invalid')
                allRowsValid = false
              }
            })
          }

          all_ajax.push(
            $.ajax(options.src, {
              method: 'PUT',
              dataType: 'json',
              data: data
            }).fail(failHandler.bind(this, $this, template))
              .always(function () {
                $('.loader', $this).addClass('d-none')
                if (options.add.editFunction) options.add.editFunction()
              })
          )
        })

        if (!allRowsValid) return
        $.when.apply($, all_ajax).then(function () {
          $('.loader', $this).addClass('d-none')
          edit_btn.html('Edit') // TODO: remove hardcoding of name Edit
          add_btn.prop('disabled', false)
          if (options.edit.done) options.edit.done()
        })

        template_data.isEdit = false
        render_template('table', template_data, options, $this, template)
      } else if (edit_btn.html().toLowerCase() == 'edit') {
        edit_btn.html('Save') // TODO: remove hardcoding of name Save
        add_btn.prop('disabled', true)
        template_data.isEdit = true
        render_template('table', template_data, options, $this, template)
        add_edit_events($this, edit_btn)
        $this.trigger({ type: 'editmode' })
      }
    })
}

function actionHandler($this, options, template) {
  var default_actions = {
    'delete': function (arg) {
      return $.ajax(options.src, { method: 'DELETE', dataType: 'json', data: arg.row })
        .done(function () { $('tr[data-row="' + arg.index + '"]', $this).remove() })
    }
  }
  $this.on('click', '[data-action]', function () {
    var arg = {
      row: $(this).closest('[data-val]').data('val'),
      index: $(this).closest('[data-row]').data('row'),
      notify: notify.bind(this, $this, template)
    }
    var action = $(this).data('action')

    var method = (options.actions && (options.actions.filter(function (each_action) { return action in each_action }).length > 0))
      ? options.actions.filter(function (each_action) { return action in each_action })[0][action]
      : default_actions[action]

    var deferred = method(arg)
    if (deferred && deferred.always) {
      $('.loader', $this).removeClass('d-none')
      deferred
        .always(function () { $('.loader', $this).addClass('d-none') })
        .fail(failHandler.bind(this, $this, template))
    }
  })
}


function remove_quotes(str) {
  return str.toString().replace(/["']/g, '')
}

function notify($this, template, message) {
  var $note = $('.note', $this)
  if (!$note.length)
    $note = $('<div class="note"></div>').appendTo($this)
  $note.html(template['error']({ message: message }))
}

function failHandler($this, template, xhr, status, message) {
  var error = status + ': ' + message
  if (xhr.readyState == 0)
    error += ' (cannot connect to server)'
  notify($this, template, error)
}


function add_edit_events($this, save_btn) {
  $('tbody :input', $this)
    // When the user types something, mark the row as changed
    .on('change', function () {
      $(this).parents('tr').addClass('edited-row')
    })
    // When the user presses Enter, click on the "Save" button
    .on('keypress', function (e) {
      if (e.keyCode == 13) {
        $(this).blur()      // Remove focus so that change / .edited-row is triggered
        save_btn.trigger('click')
      }
    })
    // Focus on the first element
    .eq(0).focus()
}
